import { useQuery, QueryOptions } from '@tanstack/react-query';
import { api } from '../scripts/DataServices';
import { IRuleJson } from '../scripts/ApiTypes';
import { CacheKey } from './CacheKeys';

interface Rule {
    id: number;
    name: string;
    fallthrough: boolean;
    originalCreatorName: string;
    nameOfLastModifier: string;
    state: number;
    ruleLogic: IRuleJson;
}

export interface RuleForMailbox {
    priority: number;
    mainMailboxId: number;
    rule: Rule;
}

export function UseGetRulesForMailbox(mailboxId: number) {
    return useQuery({
        queryKey: CacheKey.RulesForMailbox(mailboxId),
        queryFn: async () => api.get<RuleForMailbox[]>('/rules/mailbox/' + mailboxId).then((data) => data.data),
        enabled: !(mailboxId === undefined),
    });
}
