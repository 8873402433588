import { useQuery } from '@tanstack/react-query';
import { api } from '../scripts/DataServices';
import { CacheKey } from './CacheKeys';

export type MainMailbox = {
    id: number;
    address: string;
};

export function UseGetMainMailboxes() {
    return useQuery({
        queryKey: CacheKey.MainMailboxes,
        queryFn: async () => {
            const result = await api.get<MainMailbox[]>('mailbox/mainmailbox');
            return result.data;
        },
    });
}
