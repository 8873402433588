import { Tab, Tabs } from 'react-bootstrap';
import { SortingTestUpload } from './SortingTestUpload';
import { SortingTestForm } from './SortingTestForm';
import { useState } from 'react';
import { MainMailboxSelect } from '../Utility/MainMailboxSelect';
import { MainMailbox, UseGetMainMailboxes } from '../../hooks/UseGetMainMailboxes';
import { ClipLoader } from 'react-spinners';

export const SortingTest = () => {
    const mainMailboxes = UseGetMainMailboxes();
    return (
        <>
            <h1>Sorteringstest</h1>
            <p>
                Her kan du teste hvordan både dine regler og AI model ville sortere en vilkårlig mail, hvis den bliver indsendt til din hovedpostkasse. Udfyld
                felterne som de ville være i mailen, eller upload en gemt mail i .msg format.
            </p>
            {mainMailboxes.isLoading && (
                <div className="flex justify-center">
                    <ClipLoader />
                </div>
            )}
            {mainMailboxes.data && <SortingSelector mainMailboxes={mainMailboxes.data}></SortingSelector>}
        </>
    );
};

type props = {
    mainMailboxes: MainMailbox[];
};

function SortingSelector({ mainMailboxes }: props) {
    const [mailboxId, setMailboxId] = useState<number>(mainMailboxes[0].id);
    return (
        <>
            <MainMailboxSelect selected={mailboxId} setMailbox={setMailboxId} availableMailboxes={mainMailboxes}></MainMailboxSelect>
            <Tabs defaultActiveKey="manual">
                <Tab className="pt-2" eventKey="manual" title="Indtast manuelt">
                    <SortingTestForm mailboxId={mailboxId}></SortingTestForm>
                </Tab>
                <Tab className="pt-2" eventKey="file" title="Upload fil">
                    <SortingTestUpload mailboxId={mailboxId}></SortingTestUpload>
                </Tab>
            </Tabs>
        </>
    );
}
