import { Alert, Button, Modal } from 'react-bootstrap';
import { getOnboardingStatus, startOnboardingTest } from '../../scripts/DataServices';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { ClipLoader } from 'react-spinners';

export const OnboardingPage = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const startTest = useQuery({ queryKey: ['onboarding'], queryFn: startOnboardingTest, enabled: false });

    var isConflict = (err: any) => {
        console.log(err);
        if (axios.isAxiosError(err)) {
            return err.status == 409;
        }
        return false;
    };

    const testStatus = useQuery({ queryKey: ['onboardingStatus'], queryFn: getOnboardingStatus });

    const confirmOnboardingRun = () => {
        startTest.refetch();
        setShowModal(false);
    };

    return (
        <>
            {testStatus.data && testStatus.data.isQueued && (
                <Alert variant="success">
                    En testkørsel af SmartMail er allerede igang og du kan derfor ikke starte en ny.
                    {testStatus.data.startTime && ' Kørslen blev startet ' + testStatus.data.startTime.toLocaleString()}
                </Alert>
            )}
            {startTest.isSuccess && (
                <Alert variant={'success'}>
                    Testkørsel af SmartMail er startet. Afhængig af mængden af mails i hovedpostkassen kan dette tage en times tid. Resultatet af kørslen kan
                    ses på <a href="/auditlogs">fremsøgningssiden</a>.
                </Alert>
            )}
            {startTest.error && isConflict(startTest.error) && (
                <Alert variant={'warning'}>Der er allerede en testkørsel af SmartMail igang. Du kan ikke starte en ny før den seneste er afsluttet.</Alert>
            )}
            <div>
                <h1>SmartMail onboarding test</h1>
                <p className="mb-10">
                    Her kan du starte en testkørsel af SmartMail. En testkørsel vil indsamle mails fra hovedpostkassen fra de sidste 14 dage og sende dem
                    igennem SmartMail til sortering. SmartMail vil for disse mails ikke flytte dem eller videresende dem i Outlook men blot registrere hvordan
                    de ville have været fordelt. Resultatet af testkørslen kan ses på <a href="/auditlogs">fremsøgningssiden</a> og{' '}
                    <a href="/statistics">statistiksiden</a>.
                </p>
                <div className="relative flex justify-center">
                    <Button disabled={startTest.isFetching || testStatus.isLoading || testStatus.data?.isQueued} onClick={() => setShowModal(true)}>
                        Start onboarding test
                    </Button>
                    {startTest.isFetching && <ClipLoader size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Testkørsel</Modal.Title>
                </Modal.Header>
                <Modal.Body>Er du sikker på at du vil starte en ny testkørsel? Dette vil slette fremsøningsværdier fra sidste kørsel.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Fortryd
                    </Button>
                    <Button variant="primary" onClick={confirmOnboardingRun}>
                        Start
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
