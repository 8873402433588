import { useQuery } from '@tanstack/react-query';
import { ClipLoader } from 'react-spinners';
import Auth from '../../authentication/Auth';
import { fetchRules } from '../../scripts/DataServices';
import { RuleTable } from './RuleTable';

export const RuleList = () => {
    const rules = useQuery({ queryKey: ['rules'], queryFn: fetchRules });

    return (
        <>
            <h1>Regler</h1>
            {rules.isError && <p>Der opstod en intern fejl da regler skulle hentes.</p>}
            {rules.isLoading && (
                <div className="w-full flex justify-center">
                    <ClipLoader size={70} />
                </div>
            )}
            {rules.data && <RuleTable rules={rules.data} />}
        </>
    );
};
