import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteRule } from '../../scripts/DataServices';

export const RuleEditButtons = ({ ruleId, ruleName }: { ruleId: number; ruleName: string }) => {
    const queryClient = useQueryClient();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const acceptDelete = () => {
        deleteRule(ruleId).then(() => queryClient.invalidateQueries({ queryKey: ['rules'] }));
        handleClose();
    };

    return (
        <div className="flex justify-center items-center">
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Slet regel?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Er du sikker på, at du vil slette reglen '{ruleName}'?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fortryd
                    </Button>
                    <Button variant="primary" onClick={acceptDelete}>
                        Slet
                    </Button>
                </Modal.Footer>
            </Modal>
            <Button className="m-1" href={`/rule-creation/${ruleId}`}>
                <FontAwesomeIcon color="white" icon={faPencilAlt} />
            </Button>
            <Button
                as="div"
                className="m-1"
                onClick={(e) => {
                    handleShow();
                }}
                variant="secondary"
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </div>
    );
};
