import { UseGetMainMailboxes } from '../../hooks/UseGetMainMailboxes';
import { Button, Form } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { MainMailboxSelect } from '../Utility/MainMailboxSelect';
import { useEffect } from 'react';

type FormProps = {
    nextPage: () => void;
    prevPage: () => void;
    setMailbox: (id: number) => void;
    mailbox?: number;
};

export function AssociatedMailboxesForm({ nextPage, prevPage, mailbox, setMailbox }: FormProps) {
    const mainMailboxes = UseGetMainMailboxes();

    useEffect(() => {
        if (mainMailboxes.isSuccess && !mailbox) {
            setMailbox(mainMailboxes.data[0].id);
        }
    }, [mainMailboxes.isSuccess]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        nextPage();
    };

    return (
        <>
            <h2 className="text-center">Hovedpostkasse</h2>
            <p className="text-center">Vælg hvilken hovedpostkasse som reglen skal være aktiv for.</p>
            <Form onSubmit={handleSubmit}>
                <>
                    {mainMailboxes.error && <p>Der opstod en intern fejl. Prøv igen senere</p>}
                    {mainMailboxes.isLoading && <ClipLoader></ClipLoader>}
                    {mainMailboxes.data && (
                        <MainMailboxSelect
                            availableMailboxes={mainMailboxes.data}
                            selected={mailbox ?? mainMailboxes.data[0].id}
                            setMailbox={setMailbox}
                        ></MainMailboxSelect>
                    )}
                </>
                <div className="flex justify-between">
                    <Button variant="secondary" onClick={prevPage}>
                        Tilbage
                    </Button>
                    <Button type="submit">Næste</Button>
                </div>
            </Form>
        </>
    );
}
