import { IEmailItem } from '../../scripts/ApiTypes';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DOMPurify from 'dompurify';

export const EmailDisplay = ({ email }: { email: IEmailItem }) => {
    return (
        <div>
            <h1>{email.subject}</h1>
            <p>Afsender: {email.sender}</p>
            <p>Modtager: {email.recipient}</p>
            <p>Modtaget: {new Date(email.dateTimeReceived).toLocaleString()}</p>
            {!email.body && !email.htmlBody && !email.attachmentText ? (
                <p>Denne email er mere end 30 dage gammel og brødteksten samt vedhæftninger vises der for ikke</p>
            ) : (
                <Tabs className="mb-2">
                    <Tab eventKey="body" title="Brødtekst">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.htmlBody) }}></div>
                    </Tab>
                    {email.attachmentText && (
                        <Tab eventKey="attachments" title="Vedhæftninger">
                            {email.attachmentText}
                        </Tab>
                    )}
                </Tabs>
            )}
        </div>
    );
};
