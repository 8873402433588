import { Button, Form } from 'react-bootstrap';
import { UserRole } from '../../hooks/UseGetUserRoles';
import { useState } from 'react';
import { UseMutateRoles } from '../../hooks/UseMutateRoles';

type props = {
    userRoles: UserRole[];
    roles: string[];
};

export function RoleTable({ userRoles, roles }: props) {
    const [userRoleUpdates, setUserRoleUpdates] = useState<UserRole[]>(userRoles);

    const mutateRoles = UseMutateRoles();

    return (
        <>
            <h1>Rettigheder til hovedpostkasser</h1>
            <p>Brugere med rettigheder til en hovedpostkasse har ret til at oprette regler for postkassen, samt at fremsøge sorterede mails.</p>
            <table className="mb-2 shadow-md w-full">
                <thead className="bg-main-500 text-gray-100">
                    <tr>
                        <td className="px-2 py-2">Brugere/Roller</td>
                        {roles.map((role) => (
                            <td className="px-2 py-3 text-center" key={role}>
                                {role}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {userRoleUpdates.map((userRole) => (
                        <tr className="even:bg-slate-100" key={userRole.userId}>
                            <td className="p-2">{userRole.userName}</td>
                            {roles.map((role) => (
                                <td key={role} className="text-center">
                                    <Form.Check
                                        type="checkbox"
                                        checked={userRole.roles.includes(role)}
                                        onChange={(e) => {
                                            if (e.currentTarget.checked) {
                                                userRole.roles.push(role);
                                            } else {
                                                const roleIndex = userRole.roles.findIndex((r) => r === role);
                                                userRole.roles.splice(roleIndex, 1);
                                            }
                                            setUserRoleUpdates([...userRoleUpdates]);
                                        }}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button onClick={() => mutateRoles.mutate({ userRoles: userRoleUpdates })}>Gem</Button>
        </>
    );
}
