import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserRole } from './UseGetUserRoles';
import { api } from '../scripts/DataServices';
import toast from 'react-hot-toast';
import { CacheKey } from './CacheKeys';

type UserRoleUpdate = {
    userRoles: UserRole[];
};

export function UseMutateRoles() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (roles: UserRoleUpdate) => api.put('role', roles),
        onSuccess: () => {
            toast.success('Rolleændringer er blevet gemt!');
            queryClient.invalidateQueries({ queryKey: CacheKey.UserRoles });
        },
        onError: () => {
            toast.error('Der opstod en fejl');
        },
    });
}
