import Button from 'react-bootstrap/Button';
import { RuleTest } from './RuleTest';
import { RuleFormData } from './RuleCreation';
import { getRuleDescription } from './RuleSummation';
import { IRuleCreation } from '../../scripts/ApiTypes';
import { useNavigate } from 'react-router-dom';

type FormProps = {
    prevPage: () => void;
    rule: RuleFormData;
    createFunction: (rule: IRuleCreation) => Promise<any>;
};

export const ConfirmForm = ({ prevPage, rule, createFunction }: FormProps) => {
    let navigate = useNavigate();

    let ruleDTO: IRuleCreation = {
        name: rule.name,
        fallthrough: rule.fallthrough,
        ruleJson: {
            actions: rule.actions,
            conditions: rule.conditions,
            exceptions: rule.exceptions,
        },
        mailboxId: rule.mainMailboxId!,
    };

    const summation = getRuleDescription({
        actions: rule.actions,
        conditions: rule.conditions,
        exceptions: rule.exceptions,
    });

    const createRuleBtn = () => {
        createFunction(ruleDTO)
            .then(() => navigate(-1))
            .catch(() => window.alert('Der opstod en fejl da reglen skulle indsendes'));
    };

    return (
        <>
            <h2 className="text-center">Bekræft regel</h2>
            <table className="border-y-2 mb-4 w-full">
                <tbody>
                    <tr>
                        <td className="pt-4 pb-2">Navn:</td>
                        <td className="pt-4 pb-2">{rule.name}</td>
                    </tr>
                    <tr>
                        <td>Regelmotor fortsættelse</td>
                        <td>{rule.fallthrough ? 'Aktiveret' : 'Deaktiveret'}</td>
                    </tr>
                    <tr>
                        <td className="pb-4 pt-2">Regel:</td>
                        <td className="pb-4 pt-2">{summation}</td>
                    </tr>
                </tbody>
            </table>
            {<RuleTest rule={ruleDTO}></RuleTest>}
            <div className="flex justify-between">
                <Button onClick={prevPage} variant="danger">
                    Tilbage
                </Button>
                <Button onClick={createRuleBtn}>Bekræft</Button>
            </div>
        </>
    );
};
