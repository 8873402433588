import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Profile } from './ProfilePages/Profile';
import { Email } from './ProfilePages/Email';
import TwoFactorAuth from './ProfilePages/TwoFactorAuth';
import Auth from '../../authentication/Auth';
import { Password } from './ProfilePages/Password';
import { useQuery } from '@tanstack/react-query';
import { ClipLoader } from 'react-spinners';
import { getUserDetails } from '../../scripts/AuthenticationServices';

export const Account = () => {
    const logout = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        await Auth.logout();
    };

    var user = useQuery({ queryKey: ['user details'], queryFn: getUserDetails });

    return (
        <>
            {user.isError && <p>Der opstod en uventet fejl da systemet skulle hente brugeroplysninger</p>}
            {user.isLoading && <ClipLoader></ClipLoader>}
            {user.data && (
                <>
                    <h1>Min konto</h1>
                    <Tab.Container defaultActiveKey="profile">
                        <div className="flex shadow-sm p-4">
                            <Nav variant="pills" className="flex flex-col pr-4 border-r">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">Profil</Nav.Link>
                                </Nav.Item>
                                {!user.data.external && (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="email">Email</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="password">Password</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="pb-2">
                                            <Nav.Link eventKey="twofactor">Totrinsbekræftelse</Nav.Link>
                                        </Nav.Item>
                                    </>
                                )}
                                <Nav.Item className="pt-2 border-t" onClick={logout}>
                                    <Nav.Link className="text-red-600">Log ud</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="w-1/2 pl-4">
                                <Tab.Pane eventKey="profile">
                                    <Profile userName={user.data.userName} />
                                </Tab.Pane>
                                {!user.data.external && (
                                    <>
                                        <Tab.Pane eventKey="email">
                                            <Email email={user.data.email} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="password">
                                            <Password />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="twofactor">
                                            <TwoFactorAuth />
                                        </Tab.Pane>
                                    </>
                                )}
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </>
            )}
        </>
    );
};
