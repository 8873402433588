import { useQueries, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { postFileTest } from '../../scripts/DataServices';
import ClipLoader from 'react-spinners/ClipLoader';
import { SortingTestResult } from './SortingTestResult';

export const SortingTestUpload = ({ mailboxId }: { mailboxId?: number }) => {
    const [file, setFile] = useState<File>();

    const testResult = useQuery({ queryKey: ['sortingtest', file], queryFn: () => postFileTest(file, mailboxId ?? 1), enabled: false });

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    return (
        <>
            <Form>
                <Form.Control className="mb-2" onChange={handleFileChange} accept=".msg, .eml" type="file" />
                <Button
                    disabled={mailboxId === undefined || file === undefined}
                    onClick={(e) => {
                        e.preventDefault();
                        testResult.refetch();
                    }}
                >
                    Indsend
                </Button>
            </Form>
            <div>
                {testResult.isFetching && <ClipLoader></ClipLoader>}
                {testResult.isError && <p>Der opstod en fejl</p>}
                {testResult.data && (
                    <>
                        <Form>
                            <Form.Label>Afsender:</Form.Label>
                            <Form.Control disabled value={testResult.data.mailValues.sender}></Form.Control>
                            <Form.Label>Modtager:</Form.Label>
                            <Form.Control disabled value={testResult.data.mailValues.recipient}></Form.Control>
                            <Form.Label>Emnefelt:</Form.Label>
                            <Form.Control disabled value={testResult.data.mailValues.subject}></Form.Control>
                            <Form.Label>Indhold:</Form.Label>
                            <Form.Control disabled as="textarea" value={testResult.data.mailValues.body}></Form.Control>
                            <Form.Label>Vedhæftninger:</Form.Label>
                            <Form.Control disabled className="mb-2" as="textarea" value={testResult.data.mailValues.attachments}></Form.Control>
                        </Form>
                        <SortingTestResult aiResult={testResult.data.testResult.aiResult} ruleHits={testResult.data.testResult.ruleHits}></SortingTestResult>
                    </>
                )}
            </div>
        </>
    );
};
