import { useQuery } from '@tanstack/react-query';
import { CacheKey } from './CacheKeys';
import { api } from '../scripts/DataServices';

export interface UserRole {
    userId: number;
    userName: string;
    roles: string[];
}

export function useGetUserRoles() {
    return useQuery({
        queryKey: CacheKey.UserRoles,
        queryFn: async () =>
            api
                .get<UserRole[]>('role/userroles')
                .then((r) => r.data)
                .catch((er) => console.log(er)),
    });
}
